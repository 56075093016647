import React from 'react'
import PageHeader from '../components/PageHeader'

const NotFoundPage = () => (
  <div className="four-oh-four">
    <PageHeader
      title="Not Found"
      subtitle="The page you are looking for doesn't exist"
    />
  </div>
)

export default NotFoundPage
